<template>
    <div
        v-if="dropActive && !isMobile"
        class="navbar-dropdown search"
    >
        <div class="drop-content">
            <div
                v-for="product in hints"
                :key="`drop-item-${product.code}`"
                class="item d-flex"
                @click="handleClick({ name: 'Produs', params: { slug: product.slug } }, product.id)"
            >
                <div class="image">
                    <img
                        :src="product.thumbnail"
                        :alt="product.name"
                    >
                </div>
                <div class="content">
                    <div class="drop-head">
                        <p class="sm-sbold title">
                            {{ product.name }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="hints.length > 5 || hints.length == 0"
            class="drop-action"
        >
            <a
                v-if="hints.length > 5"
                @click="searchPage()"
            >
                <search-icon />
                <p>{{ $t('header.search.all') }}</p>
            </a>
            <p
                v-if="hints.length == 0"
                class="sm-sbold"
            >
                {{ $t('header.search.noResult') }}
            </p>
        </div>
    </div>
</template>

<script>
    import SearchIcon from 'vue-feather-icons/icons/SearchIcon';
    import { mapActions, mapGetters, mapState } from 'vuex';

    export default {
        name: 'NavbarSearchDropdown',
        components: {
            SearchIcon,
        },
        data() {
            return {
                dropActive: false,
            };
        },
        props: {
            active: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                default: 'cart',
            },
            searchKey: {
                type: String,
                default: '',
            },
        },
        computed: {
            ...mapState('search', ['hints']),
            ...mapGetters('auth', ['isLogged']),
            isMobile() {
                return this.$screen.breakpoint == 'md';
            },
        },
        methods: {
            ...mapActions('category', ['getProducts']),
            ...mapActions('search', ['storeSearch']),
            searchPage() {
                if (this.$route.name == 'Cautare') {
                    this.getProducts({
                        query: { type: 'search', value: this.searchKey },
                        isSearch: true,
                    });
                } else {
                    this.$router
                        .push({
                            name: 'Cautare',
                            query: { key: this.searchKey },
                        })
                        .catch(() => {
                            //
                        });
                }
                this.dropActive = false;
                let input = document.getElementById('search');
                input.blur();
            },
            handleClick(path, productId) {
                if (this.isLogged) {
                    this.storeSearch({
                        productId: productId,
                        searchKey: this.searchKey,
                    });
                }
                this.$router.push(path).catch(() => {
                    //
                });
            },
        },
        watch: {
            active() {
                this.dropActive = this.active;
            },
        },
    };
</script>
