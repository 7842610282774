<template>
    <form
        class="form-inline"
        autocomplete="off"
        @submit.prevent=""
    >
        <div
            v-click-outside="closeDropdown"
            class="search-container"
        >
            <input
                v-if="isMobileDevice"
                id="search"
                v-model="searchInput"
                type="text"
                name="key"
                :placeholder="$t('header.search.placeholder')"
                @keyup.enter="searchPage()"
            >
            <input
                v-else
                id="search"
                v-model="searchInput"
                type="text"
                name="key"
                :placeholder="$t('header.search.placeholder')"
                @keyup.enter="searchPage()"
                @keyup="searchDropdown()"
                @focus="showDropdown()"
            >
            <b-button
                aria-label="cautare"
                variant="light"
                class="btn link"
                @click="searchPage()"
            >
                <search-icon />
            </b-button>
            <navbar-search-dropdown
                :active="active"
                :search-key="modifiedInput"
            />
        </div>
    </form>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    import SearchIcon from 'vue-feather-icons/icons/SearchIcon';
    import { mapActions, mapState } from 'vuex';

    import UserAgentMixin from '@/mixins/userAgentMixin';

    import NavbarSearchDropdown from './NavbarSearchDropdown';

    export default {
        mixins: [UserAgentMixin],
        name: 'NavbarSearch',
        components: {
            SearchIcon,
            NavbarSearchDropdown,
        },
        data() {
            return {
                searchInput: '',
                modifiedInput: '',
                active: false,
            };
        },
        computed: {
            ...mapState('category', ['searchKey']),
        },
        methods: {
            ...mapActions('search', ['getHints']),
            ...mapActions('category', ['getProducts']),
            searchDropdown() {
                let currentSearchKey = this.convertInput();
                if (currentSearchKey != this.searchKey && currentSearchKey.length > 2) {
                    this.getHints(currentSearchKey);
                    this.active = true;
                    this.modifiedInput = currentSearchKey;
                }
            },
            searchPage() {
                let currentSearchKey = this.convertInput();
                if (currentSearchKey.length > 2) {
                    this.modifiedInput = currentSearchKey;
                    this.goSearch();
                }
            },
            closeDropdown() {
                this.active = false;
            },
            goSearch() {
                if (this.$route.name == 'Cautare') {
                    this.getProducts({
                        query: { type: 'search', value: this.modifiedInput },
                        isSearch: true,
                    });
                } else {
                    this.$router
                        .push({
                            name: 'Cautare',
                            query: { key: this.modifiedInput },
                        })
                        .catch(() => {
                            //
                        });
                }
            },
            showDropdown() {
                let currentSearchKey = this.convertInput();
                if (currentSearchKey.length > 2) {
                    this.active = true;
                }
            },
            convertInput() {
                return this.searchInput.replace(/\s\s+/g, ' ').toLowerCase();
            },
        },
        watch: {
            $route: {
                handler() {
                    this.active = false;
                    this.searchInput = this.searchKey;
                    let input = document.getElementById('search');
                    input.blur();
                },
            },
            searchKey: {
                handler() {
                    this.searchInput = this.searchKey;
                },
            },
        },
        directives: {
            ClickOutside,
        },
    };
</script>
